/*
 * @Author: guoguo 
 * @Date: 2018-10-18 16:48:56 
 * @Last Modified by: songyang
 * @Last Modified time: 2020-09-04 17:29:46
 */

import { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { renderRoutes } from 'react-router-config';
import { withRouter } from 'react-router-dom';
import * as action from '../actions/Base';
// import zx from '../assets/js/zhixinHybrid.min.js';
import * as localForage from "localforage";
import '../assets/css/base.less';
import axios from 'axios';
import { Toast } from 'antd-mobile';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      goRender: false
    };
  }
  componentDidMount() {
    Toast.loading('加载中...', 0);
    window.onload = () => {
      // 注入bridge脚本
      const script = document.createElement("script");
      script.src = "/bridgeWeb.js";
      document.body.appendChild(script);
    }
    window.AppThis = this
    // 获取当前用户信息
    this.props.actions.getUserInfo()
      .then((user) => {
        this.props.actions.isLogin();
        window.loginFlag = true;
        this.setState({ goRender: true });
        Toast.hide();
        this.localForageInit(user);// 初始化本地存储
        this.props.actions.getLiveConfig();// 获取直播相关配置
      })
      .catch(err => {
        if (err.message !== 'Network Error') {
          if (window.api && window.api.getAppToken) {
            this.bridgeReady();
          } else {
            document.addEventListener('onBridgeReady', this.bridgeReady);
          }
        }
      })
    // 设置html的font-size
    this.setHtmlFontSize()
    let title = this._getTitle()
    this.props.actions.setTitle(title)
    // 监听路由变化
    this.props.history.listen(route => {
      console.log('route change', route)
      let title = this._getTitle(route.pathname)
      this.props.actions.setTitle(title)
    })
    var html = document.documentElement;
    window.onresize = () => {
      var hw = document.documentElement.clientWidth;
      if (hw > 750) {
        hw = 750;
      }
      html.style.fontSize = hw / 10 + 'px';
    }
  }

  componentDidCatch(error) {
    // js执行错误时将日志打印到node端，并且在特定错误时跳到err页面
    const { message, fileName, lineNumber, columnNumber, stack } = error;
    const errObj = {
      errorMessage: message,
      lineNumber,
      scriptURI: fileName,
      columnNumber,
      errorStack: stack,
    }
    axios.post(`/service/logError`, { error: errObj }).then(() => {
      this.locationToErrPage(error)
    }).catch(() => {
      this.locationToErrPage(error)
    })
  }

  /**
   * @description 根据错误类型重定向到错误页
   * @param error 捕获的错误
   */
  locationToErrPage = (error) => {
    window.location.replace(`${window.location.origin}/err?path=${window.location.pathname}${window.location.search}`);
    // if (
    //   error instanceof TypeError ||
    //   error instanceof SyntaxError ||
    //   error instanceof ReferenceError ||
    //   error instanceof RangeError ||
    //   error instanceof URIError ||
    //   error instanceof EvalError
    // ) {
    //   window.location.replace(`${window.location.origin}/err?path=${window.location.pathname}${window.location.search}`);
    // }
  }

  /**
   * @description 原生端能力准备完毕之后会调用该方法
   */
  bridgeReady = () => {
    window.api.requestAuthCode({
      h5AppId: 'ff37ee8478fb4c9e9fb472076c6dc507',// 沙箱环境h5AppId
      // h5AppId: 'a65708ccd3b24c229e97df4de4b23459',// 生产环境h5AppId
    }, (ret) => {
      console.log('res:', ret);
      console.log('authCode is:', ret.authCode);
      const authCode = ret.authCode;
      if (!authCode) {
        window.location.replace(`${window.location.origin}/err?path=${window.location.pathname}${window.location.search}`);
        return;
      }
      this.props.actions.LoginForCode(authCode)
        .then((user) => {
          this.props.actions.isLogin();
          window.loginFlag = true;
          this.setState({ goRender: true });
          Toast.hide();
          this.localForageInit(user);// 初始化本地存储
          this.props.actions.getLiveConfig();// 获取直播相关配置
        })
        .catch(err => {
          if (err.response && (err.response.status === 403)) {
            window.location.replace('/403');
          } else {
            window.location.replace(`${window.location.origin}/err?path=${window.location.pathname}${window.location.search}`);
          }
        });
    });
  }

  // localForage init
  localForageInit = (user) => {
    localForage.config({
      name: `${user.id}`,
    })
  }

  // 获取当前路由的title
  _getTitle = (pathname) => {
    const { routes } = this.props.route;
    pathname = pathname || this.props.location.pathname;
    let title = "";
    routes.forEach((item) => {
      if (pathname === item.path) {
        title = item.title;
      }
      if (item.path && item.path.indexOf("/:") !== -1) {//当路由中含有参数时
        let pathStr = item.path.split("/:")[0];
        let pathArr = pathStr.split("/");
        let paramCount = item.path.split("/:").length - 1;//参数个数
        if (pathname.indexOf(pathStr) !== -1 && pathname.split("/").length - paramCount === pathArr.length) {
          title = item.title;
        }
      }
    })
    return title
  }
  // 设置html的font-size
  setHtmlFontSize() {
    var html = document.documentElement;
    var hw = document.documentElement.clientWidth;
    if (hw > 750) {
      hw = 750;
    }
    html.style.fontSize = hw / 10 + 'px';
  }

  render() {
    return this.state.goRender ? renderRoutes(this.props.route.routes) : null
  }
}
App.propTypes = {
  route: PropTypes.object,
  actions: PropTypes.object,
}

function mapStateToProps(state) {
  return {
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(action, dispatch),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(App));
